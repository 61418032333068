import { createStore } from "vuex"

const store = createStore({
  state() {
    return {
      theme: localStorage.getItem("theme"),
      setupIntent: null,
      searchParams: null,
      searchParamsForCurrentSearch: null,
      previousSearchParams: null,
      searchResults: null,
      searchFormData: null,
      searchNextStep: null,
      searchNextPage: null,
      selectedDateTime: null,
      selectedBookingSlot: null,
      reservation: null,
      cart: null,
      customer: null,
      searchChanged: false,
      timerExtensionsUsed: 0,
      reservationSettings: null,
      addonPaymentContinueButtonInViewport: false,
      errorPageMessage: null,
      trackingEnabled: true,
      timer: null,
      updateNextStep: true,
      weeksMovedForwardsOnCalendar: 0,
    }
  },
  getters: {},
  mutations: {
    changeTheme(state) {
      if (state.theme === "light") {
        state.theme = "dark"
      } else {
        state.theme = "light"
      }
    },
    setErrorPageMessage(state, message) {
      state.errorPageMessage = message
    },
    storePaymentIntent(state, setupIntent) {
      state.setupIntent = setupIntent
    },
    updateSearchParams(state, params) {
      state.searchParams = JSON.parse(JSON.stringify(params))
    },
    updateSearchParamsForCurrentSearch(state, params) {
      state.searchParamsForCurrentSearch = JSON.parse(JSON.stringify(params))
    },
    updatePreviousSearchParams(state, params) {
      state.previousSearchParams = JSON.parse(JSON.stringify(params))
    },
    updateSearchResults(state, results) {
      state.searchResults = results
    },
    updateSearchFormData(state, formData) {
      state.searchFormData = formData
    },
    updateSearchNextStep(state, nextStep) {
      state.searchNextStep = nextStep
    },
    updateSelectedDateTime(state, dateTime) {
      state.selectedDateTime = dateTime
    },
    updateSearchNextPage(state, nextPage) {
      state.searchNextPage = nextPage
    },
    updateReservation(state, reservation) {
      state.reservation = reservation
    },
    updateCart(state, cart) {
      state.cart = cart
    },
    updateCustomer(state, customer) {
      state.customer = customer
    },
    updateSearchChanged(state, changed) {
      state.searchChanged = changed
    },
    updateTimerExtensionsUsed(state, extensionsUsed) {
      state.timerExtensionsUsed = extensionsUsed
    },
    updateReservationSettings(state, settings) {
      state.reservationSettings = settings
    },
    updateAddonPaymentContinueButtonInViewport(state, inViewport) {
      state.addonPaymentContinueButtonInViewport = inViewport
    },
    updateTrackingEnabled(state, trackingEnabled) {
      state.trackingEnabled = trackingEnabled
    },
    updateTimer(state, timer) {
      state.timer = timer
    },
    updateNextStep(state, updateNextStep) {
      state.updateNextStep = updateNextStep
    },
    addWeeksMovedForwardsOnCalendar(state) {
      state.weeksMovedForwardsOnCalendar = state.weeksMovedForwardsOnCalendar + 1
    },
    subtractWeeksMovedForwardOnCalendar(state) {
      state.weeksMovedForwardsOnCalendar = state.weeksMovedForwardsOnCalendar - 1
    },
    updateWeeksMovedForwardOnCalendar(state, weeksMovedForwardsOnCalendar) {
      state.weeksMovedForwardsOnCalendar = weeksMovedForwardsOnCalendar
    },
    updateSelectedBookingSlot(state, selectedBookingSlot) {
      state.selectedBookingSlot = selectedBookingSlot
    },
  },
  actions: {
    changeTheme(context) {
      context.commit("changeTheme")
      localStorage.theme = context.state.theme
      if (context.state.theme === "dark") {
        document.documentElement.classList.add("dark")
      } else {
        document.documentElement.classList.remove("dark")
      }
    },
    storePaymentIntent(context, setupIntent) {
      context.commit("storePaymentIntent", setupIntent)
    },
    updateSearchParams(context, params) {
      context.commit("updateSearchParams", params)
    },
    updateSearchParamsForCurrentSearch(context, params) {
      context.commit("updateSearchParamsForCurrentSearch", params)
    },
    updatePreviousSearchParams(context, params) {
      context.commit("updatePreviousSearchParams", params)
    },
    updateSearchResults(context, results) {
      context.commit("updateSearchResults", results)
    },
    updateSearchFormData(context, formData) {
      context.commit("updateSearchFormData", formData)
    },
    updateSearchNextStep(context, nextStep) {
      context.commit("updateSearchNextStep", nextStep)
    },
    updateSearchNextPage(context, nextPage) {
      context.commit("updateSearchNextPage", nextPage)
    },
    updateSelectedDateTime(context, dateTime) {
      context.commit("updateSelectedDateTime", dateTime)
    },
    updateReservation(context, reservation) {
      context.commit("updateReservation", reservation)
    },
    updateCart(context, cart) {
      context.commit("updateCart", cart)
    },
    updateCustomer(context, customer) {
      context.commit("updateCustomer", customer)
    },
    updateSearchChanged(context, changed) {
      context.commit("updateSearchChanged", changed)
    },
    updateTimerExtensionsUsed(context, extensionsUsed) {
      context.commit("updateTimerExtensionsUsed", extensionsUsed)
    },
    updateReservationSettings(context, settings) {
      context.commit("updateReservationSettings", settings)
    },
    updateAddonPaymentContinueButtonInViewport(context, inViewport) {
      context.commit("updateAddonPaymentContinueButtonInViewport", inViewport)
    },
    updateTrackingEnabled(context, trackingEnabled) {
      context.commit("updateTrackingEnabled", trackingEnabled)
    },
    resetSearch(context) {
      context.state.setupIntent = null
      context.state.searchParams = null
      context.state.searchParamsForCurrentSearch = null
      context.state.previousSearchParams = null
      context.state.searchResults = null
      context.state.searchFormData = null
      context.state.searchNextStep = null
      context.state.searchNextPage = null
      context.state.searchChanged = false
      context.state.timerExtensionsUsed = 0
      context.state.selectedDateTime = null
    },
    updateTimer(context, timer) {
      context.commit("updateTimer", timer)
    },
    updateNextStep(context, updateNextStep) {
      context.commit("updateNextStep", updateNextStep)
    },
    updateWeeksMovedForwardOnCalendar(context, { type, value }) {
      if (type === "add") {
        context.commit("addWeeksMovedForwardsOnCalendar")
      }
      if (type === "subtract") {
        context.commit("subtractWeeksMovedForwardOnCalendar")
      }
      if (type === "set") {
        context.commit("updateWeeksMovedForwardOnCalendar", value)
      }
    },
    updateSelectedBookingSlot(context, selectedBookingSlot) {
      context.commit("updateSelectedBookingSlot", selectedBookingSlot)
    },
  },
})

export default store
