//./plugins/posthog.js
import posthog from "posthog-js";

// TODO: These posthog details, api_host and key should be stored in the database PER BRAND.
// However, it's currently unclear if the Vue booking flow will continue to live in the next iteration of the booking flow.
export default {
  install (app) {
    app.config.globalProperties.$posthog = posthog.init(
      import.meta.env.VITE_HIJINGO_POSTHOG_API_KEY, // This is a public key, leaving it out of the .env file for now.
      {
        api_host: "https://ev.hijingo.com/",
        person_profiles: 'identified_only',
      }
    );
  },
};