import { createApp, h } from "vue"
import { createInertiaApp, Head, Link } from "@inertiajs/vue3"
import store from "./stores/store"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { MotionPlugin } from "@vueuse/motion"
import { ZiggyVue } from "ziggy-vue"
import PrimeVue from "primevue/config"
import * as Sentry from "@sentry/vue"
import "../css/app.css"

import axios from "axios"
import helpers from "./helpers"
import events from "./events"
import posthogPlugin from "./plugins/posthog";

axios.interceptors.request.use((config) => {
  // Modify the URL only if it starts with "http://"
  if (config.url.startsWith("http://")) {
    config.url = config.url.replace(/^http:/, "https:")
  }

  return config
})

window.axios = axios

// This allows laravel to pick this up as an ajax request
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

// Fix for browser back button not loading the latest state of a page.
let stale = false
window.addEventListener("popstate", (e) => {
  stale = true
})

createInertiaApp({
  id: `app`,
  resolve: async (name) => resolvePageComponent(`./pages/${name}.vue`, import.meta.glob(`./pages/**/*.vue`)),
  setup({ el, App, props, plugin }) {
    const app = createApp({
      render: () => h(App, props),
      mounted: () => {
        helpers.methods.setTheme()
      },
      created() {
        // Fix for browser back button not loading the latest state of a page.
        this.$inertia.on("navigate", (event) => {
          const page = event.detail.page
          if (stale) {
            this.$inertia.get(page.url, {}, { replace: true, preserveState: false })
          }
          stale = false
        })

        axios.interceptors.response.use(
          (response) => response,
          (error) => {
            if (error?.response?.status === 500) {
              this.$store.state.errorPageMessage = error.response.data.errors?.[0]
              this.$inertia.get(route(this.getRoute("errors.general")))
            }

            if (error?.response?.status === 419 && !window.location.pathname.includes("booking-widget")) {
              // TODO: Handle this with a more helpful message for the user. Prompt reload?
              this.$store.state.errorPageMessage = error.response.data.errors?.[0]
              this.$inertia.get(route(this.getRoute("errors.general")))
            }

            if (error?.response?.status === 419 && window.location.pathname.includes("booking-widget")) {
              console.error("Booking widget session expired")
            }

            return Promise.reject(error)
          },
        )
      },
    })
      .component("Link", Link)
      .component(`Head`, Head)
      .provide(`$axios`, axios)
      .mixin({
        methods: {
          route,
        },
      })
      .mixin(helpers)
      .mixin(events)
      .use(plugin)
      .use(ZiggyVue)
      .use(store)
      .use(MotionPlugin)
      .use(PrimeVue)
      .use(posthogPlugin)
      .mount(el)

    try {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_FRONTEND_DSN_PUBLIC,
        ignoreErrors: ["fbq is not defined"],
      })
    } catch (e) {}

    return app
  },
})
