import store from "./stores/store"
import axios from "axios"
import { DateTime } from "luxon"

export default {
  data() {
    return {
      isMobile: this.mobileCheck(),
      isLaptop: this.laptopCheck(),
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    },
    darkMode() {
      return this.$store.state.theme === "dark"
    },
  },
  methods: {
    updateReservationInStore(reservation) {
      this.$store.dispatch("updateReservation", reservation)
    },
    updateReservationSettingsInStore(settings) {
      this.$store.dispatch("updateReservationSettings", settings)
    },
    updateCartInStore(cart) {
      this.$store.dispatch("updateCart", cart)
    },
    updateCustomerInStore(customer) {
      this.$store.dispatch("updateCustomer", customer)
    },
    getRoute(name) {
      return this.$page.props.global_routes[name]
    },
    asset(path) {
      return "/" + path
    },
    capitalise(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getCurrencySymbol() {
      const data =
        this.$store.state.reservation.hasOwnProperty("pending_change") &&
        this.$store.state.reservation.pending_change !== null
          ? this.$store.state.reservation.pending_change
          : this.$store.state.reservation
      const code = data.venue.country.currency
      switch (code) {
        case "GBP":
          return "£"
        case "USD":
          return "£"
        case "EUR":
          return "€"
      }
    },
    formatCurrency(amount, overrrideCountry = null) {
      const data =
        this.$store.state.reservation.hasOwnProperty("pending_change") &&
        this.$store.state.reservation.pending_change !== null
          ? this.$store.state.reservation.pending_change
          : this.$store.state.reservation
      const country = overrrideCountry || data.venue.country
      const formatter = new Intl.NumberFormat(country.locale, {
        style: "currency",
        currency: country.currency,
      })
      return formatter.format(Math.abs(amount))
    },
    formatDuration(minutes) {
      if (isNaN(minutes) || minutes < 0) {
        return null
      }

      const hours = Math.floor(minutes / 60)
      const remainingMinutes = minutes % 60

      let string = ""
      if (hours) {
        string += hours + (hours === 1 ? " hour " : " hours ")
      }
      if (remainingMinutes) {
        string += remainingMinutes + (remainingMinutes === 1 ? " minute " : " minutes ")
      }
      return string.trim()
    },
    updateReservationStatus(reservation, status, callback) {
      axios
        .patch(route(this.$page.props.brand.code + ".app.reservation.update", reservation.id), {
          status: status,
        })
        .then((r) => {
          if (callback) {
            callback()
          }
        })
    },
    setTheme() {
      if (store.state.theme === "dark") {
        document.documentElement.classList.add("dark")
      } else {
        document.documentElement.classList.remove("dark")
      }
    },
    changeTheme() {
      this.$store.dispatch("changeTheme")
    },
    setBrandColors() {
      const alphaValues = [
        { name: "-00", value: "0" },
        { name: "-10", value: "0.1" },
        { name: "-25", value: "0.25" },
        { name: "-50", value: "0.50" },
        { name: "-75", value: "0.75" },
      ]
      this.$page.props.brand_settings.palette.forEach((color) => {
        const fullColorName = color.name.replace("--f", "--ff")
        const fullColor = "rgb(" + color.color.replaceAll(" ", ",") + ")"
        document.documentElement.style.setProperty(color.name, color.color)
        document.documentElement.style.setProperty(fullColorName, fullColor)
        alphaValues.forEach((alpha) => {
          const alphaName = fullColorName + alpha.name
          const alphaColor = "rgba(" + color.color.replaceAll(" ", ",") + "," + alpha.value + ")"
          document.documentElement.style.setProperty(alphaName, alphaColor)
        })
      })
      document.documentElement.classList.add(this.$page.props.brand.code)
    },
    getCSSVariable(variable) {
      return getComputedStyle(document.documentElement).getPropertyValue(variable)
    },
    mobileCheck() {
      return window.outerWidth <= 768
    },
    laptopCheck() {
      return window.outerWidth <= 1382
    },
    checkContinueButtonInViewport(element) {
      if (typeof element !== undefined && element !== null) {
        this.$store.dispatch("updateAddonPaymentContinueButtonInViewport", this.isInViewport(element))
      }
    },
    ordinal(n) {
      var s = ["th", "st", "nd", "rd"]
      var v = n % 100
      return n + (s[(v - 20) % 10] || s[v] || s[0])
    },
    stringToColour(str, opacity = 0.5) {
      let i
      let hash = 0
      for (i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      let colour = "#"
      for (i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += ("00" + value.toString(16)).substr(-2)
      }
      colour = colour.substring(1).split("")
      if (colour.length === 3) {
        colour = [colour[0], colour[0], colour[1], colour[1], colour[2], colour[2]]
      }
      colour = "0x" + colour.join("")
      return "rgba(" + [(colour >> 16) & 255, (colour >> 8) & 255, colour & 255].join(",") + "," + opacity + ")"
    },
    getDateTime(dateString, format = null, timeZone = null) {
      let dateTimeUTC = format ? DateTime.fromFormat(dateString, format, { zone: 'UTC' }) : DateTime.fromISO(dateString, { zone: 'UTC' })
      return timeZone ? dateTimeUTC.setZone(timeZone) : dateTimeUTC
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    },
    getStripeAppearanceSettings() {
      if (this.darkMode || this.$page.props.brand.code === "hj") {
        return {
          theme: "stripe",
          labels: "floating",
          variables: {
            colorPrimary: this.getCSSVariable("--ff-color-contrast"),
            colorBackground: this.getCSSVariable("--ff-color-tertiary"),
            colorText: this.getCSSVariable("--ff-color-white"),
            fontFamily: "graphikregular",
            borderRadius: "12px",
            fontSizeBase: "15px",
            colorIcon: this.getCSSVariable("--ff-color-white"),
            colorIconTab: this.getCSSVariable("--ff-color-white"),
            colorIconTabSelected: this.getCSSVariable("--ff-color-white"),
            spacingGridRow: "16px",
            spacingGridColumn: "16px",
            spacingTab: "8px",
            spacingUnit: "3px",
          },
          rules: {
            ".Tab": {
              border: `1px solid rgba(${this.getCSSVariable("--f-color-white").replaceAll(" ", ",")}, 0.4)`,
              paddingLeft: "10px",
              //boxShadow: '0px 6px 0px 0px rgb(171, 110, 255, 0.5)'
            },
            ".Tab:hover": {
              border: `1px solid ${this.getCSSVariable("--ff-color-white")}`,
            },
            ".Tab:focus": {
              outline: "none",
              border: `1px solid ${this.getCSSVariable("--ff-color-white")}`,
              boxShadow: "0px 6px 0px 0px #4B435E",
            },
            ".Tab--selected:focus": {
              outline: "none",
              border: `1px solid ${this.getCSSVariable("--ff-color-white")}`,
              boxShadow: `0px 6px 0px 0px ${this.getCSSVariable("--ff-color-tertiary-75")}`,
            },
            ".Tab--selected": {
              border: `1px solid ${this.getCSSVariable("--ff-color-white")}`,
              boxShadow: `0px 6px 0px 0px ${this.getCSSVariable("--ff-color-tertiary-75")}`,
            },
            ".TabLabel": {
              color: this.getCSSVariable("--ff-color-white"),
            },
            ".Input": {
              border: `1px solid rgba(${this.getCSSVariable("--f-color-white").replaceAll(" ", ",")}, 0.4)`,
              paddingBottom: "",
              paddingTop: "",
            },
            ".Input:focus": {
              border: `1px solid ${this.getCSSVariable("--ff-color-white")}`,
              boxShadow: "none",
              outline: `2px solid ${this.getCSSVariable("--ff-color-contrast")}`,
              outlineOffset: "2px",
            },
          },
        }
      }
      return {
        theme: "stripe",
        labels: "floating",
        variables: {
          colorPrimary: this.getCSSVariable("--ff-color-contrast"),
          colorBackground: "#fff",
          colorText: this.getCSSVariable("--ff-color-tertiary"),
          fontFamily: "graphikregular",
          borderRadius: "12px",
          fontSizeBase: "15px",
          colorIcon: this.getCSSVariable("--ff-color-bg"),
          colorIconTab: this.getCSSVariable("--ff-color-bg"),
          colorIconTabSelected: this.getCSSVariable("--ff-color-bg"),
          spacingGridRow: "16px",
          spacingGridColumn: "16px",
          spacingTab: "8px",
          spacingUnit: "3px",
        },
        rules: {
          ".Tab": {
            border: "1px solid #d1d5db",
            paddingLeft: "10px",
            //boxShadow: '0px 6px 0px 0px rgb(171, 110, 255, 0.5)'
          },
          ".Tab:hover": {
            border: `1px solid ${this.getCSSVariable("--ff-color-bg")}`,
          },
          ".Tab:focus": {
            outline: "none",
            border: `1px solid ${this.getCSSVariable("--ff-color-tertiary")}`,
            boxShadow: `0px 6px 0px 0px ${this.getCSSVariable("--ff-color-secondary-50")}`,
          },
          ".Tab--selected:focus": {
            outline: "none",
            border: `1px solid ${this.getCSSVariable("--ff-color-tertiary")}`,
            boxShadow: `0px 6px 0px 0px ${this.getCSSVariable("--ff-color-tertiary-50")}`,
          },
          ".Tab--selected": {
            border: `1px solid ${this.getCSSVariable("--ff-color-tertiary")}`,
            boxShadow: `0px 6px 0px 0px ${this.getCSSVariable("--ff-color-tertiary-50")}`,
          },
          ".TabLabel": {
            color: this.getCSSVariable("--ff-color-tertiary"),
          },
          ".Input": {
            border: "1px solid #d1d5db",
            paddingBottom: "",
            paddingTop: "",
          },
          ".Input:focus": {
            border: "1px solid #d1d5db",
            boxShadow: "none",
            outline: `2px solid ${this.getCSSVariable("--ff-color-bg")}`,
            outlineOffset: "2px",
          },
        },
      }
    },
    getStripeFontSettings(env) {
      const url = route(this.getRoute("app.font"), "graphikregular.woff")
      //const url = 'https://bounce-gb.bengie.dev/fonts/graphikregular.woff'
      return [
        {
          family: "graphikregular",
          src: `url(${url})`,
          weight: "400",
        },
      ]
    },
    getQueryParams() {
      const urlSearchParams = new URLSearchParams(window.location.search)
      return Object.fromEntries(urlSearchParams.entries())
    },
    sendGoogleEcommerceEvent(name, parameters) {
      if (
        this.$store.getters.googleEvent(name) === undefined ||
        name === "add_to_cart" ||
        name === "remove_from_cart"
      ) {
        dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
        dataLayer.push({
          event: name,
          ecommerce: parameters,
        })
      }
    },
    sendGoogleEvent(name, parameters) {
      dataLayer.push({
        event: name,
        parameters,
      })
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.isMobile = this.mobileCheck()
      this.isLaptop = this.laptopCheck()
    })
    this.setBrandColors()
  },
}
